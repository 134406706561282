import '../../css/staff.css';
import hb from '../../img/HB.jpg';
import amy from '../../img/amy.jpg';
import im5 from '../../img/5.jpg';
import { Link } from "react-router-dom";

function Staff() {
  return(
    <div>
        <div className="breadcrumb d-flex justify-content-center">
            <h2>Our Attorneys & Staff</h2>
        </div>

        <div className="container ourstory d-flex justify-content-center">
            <div className="col-md-10 col-md-offset-1">
                <img src={hb} alt="" className="img-fluid" />
                <p>&nbsp;</p>
            </div>
        </div>
        <hr />

        <div className="container body-container">
            <div className="col-md-8">
                <h1>Our Attorneys:</h1>
                Click on name for attorney
            </div>
            <div className="col-md-8">
                <h3 className="name-link"><Link to="hainer.html"><strong>Mike Hainer</strong></Link></h3>
                <h3 className="name-link"><Link to="berman.html"><strong>Len Berman </strong></Link></h3>
                <h3 className="name-link"><Link to="malaney.html"><strong>Kay Malaney </strong></Link></h3>
            </div>
        </div>
        <hr />

        <div className="container body-container">
            <div className="col-md-8">
                <h1>Our Staff:</h1>
            </div>

            <div className="row staff-row">
                <div className="col-md-4">
                    <img src={amy} alt="" className="img-fluid" />
                </div>
                <div className="col-md-8">
                    <h2>Amy Szostek</h2>
                    <h4>Legal Assistant </h4>
                    <h4><a href="mailto:aszostek@hainerberman.com">aszostek@hainerberman.com</a></h4>
        		     </div>
    	      </div>

            <div className="row staff-row">
                <div className="col-md-4">
                    <img src={im5} alt="" className="img-fluid" />
                </div>
                <div className="col-md-8">
                    <h2>Tracy Hainer</h2>
                    <h4>Receptionist <br />
                    </h4>
                    <h4> <a href="mailto:thainer@hainerberman.com">thainer@hainerberman.com</a></h4>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Staff;
