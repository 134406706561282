import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Navigation from './navigation.js';
import Footer from './footer.js';
import Home from './home.js';
import Business from './services/business.js';
import Energy from './services/energy.js';
import RealEstate from './services/realestate.js';
import Labor from './services/labor.js';
import Banking from './services/banking.js';
import Probate from './services/probate.js';
import Civil from './services/civil.js';
import Family from './services/family.js';
import Hainer from './team/hainer.js';
import Berman from './team/berman.js';
import Malaney from './team/malaney.js';
import Staff from './team/staff.js';
import Clients from './clients.js';
import Contact from './contact.js';

function Router() {
  return (
    <BrowserRouter>
      <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />

          {/* Services */}
          <Route path="/business" element={<Business />} />
          <Route path="/energy" element={<Energy />} />
          <Route path="/realestate" element={<RealEstate />} />
          <Route path="/labor" element={<Labor />} />
          <Route path="/banking" element={<Banking />} />
          <Route path="/probate" element={<Probate />} />
          <Route path="/civil" element={<Civil />} />
          <Route path="/family" element={<Family />} />

          {/* Team */}
          <Route path="/hainer" element={<Hainer />} />
          <Route path="/berman" element={<Berman />} />
          <Route path="/malaney" element={<Malaney />} />
          <Route path="/ourstory" element={<Staff />} />

          {/* Clients */}
          <Route path="/clients" element={<Clients />} />

          {/* Contact */}
          <Route path="/contact" element={<Contact />} />
        </Routes>
      <Footer />
    </BrowserRouter>
  );
}


export default Router;
